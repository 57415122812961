<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">账号管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">账号列表（自助测）</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:10px;">
            <div title="账户名称" class="searchboxItem ci-full">
              <span class="itemLabel">账户名称:</span>
              <el-input v-model="searchForm.adminName" type="text" size="small" clearable placeholder="请输入账户名称" />
            </div>
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-input v-model="searchForm.compName" type="text" size="small" clearable placeholder="请输入单位名称">
              </el-input>
            </div>
            <div title="使用人电话" class="searchboxItem ci-full">
              <span class="itemLabel">手机号码:</span>
              <el-input v-model="searchForm.mobile" type="text" size="small" clearable placeholder="请输入手机号码" />
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select v-model="searchForm.auditState" placeholder="请选择审核状态" size="small" clearable>
                <el-option v-for="item in auditStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="账号类别" class="searchboxItem ci-full">
              <span class="itemLabel">账号类别:</span>
              <el-select v-model="searchForm.accountType" placeholder="请选择账号类别" size="small" clearable>
                <el-option v-for="item in accountTypeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
              <el-table-column label="账号名称" align="left" prop="adminName" show-overflow-tooltip min-width="100" />
              <el-table-column label="姓名" align="left" prop="fullname" show-overflow-tooltip min-width="100" />
              <el-table-column label="身份证号" align="left" prop="idCard" show-overflow-tooltip min-width="160" />
              <el-table-column label="手机号" align="left" prop="mobile" show-overflow-tooltip min-width="100" />
              <el-table-column label="单位名称" align="left" show-overflow-tooltip min-width="200">
                <template slot-scope="scope">
                  <span>{{ scope.row.compName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="账号类别" align="left" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                  {{ $setDictionary("ADMIN_QUERY_ROLE_KEY", scope.row.accountType) }}
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" min-width="100">
                <div slot-scope="scope" class="table-switch">
                  <el-switch :width="20" v-model="scope.row.state" active-value="10" inactive-value="20"
                    active-color="#13ce66" @change="(val) => { handleStu(val, scope.row.adminId) }"></el-switch>
                  <span>{{ scope.row.state == 10 ? "启用" : "禁用" }}</span>
                </div>
              </el-table-column>
              <el-table-column label="注册时间" align="left" show-overflow-tooltip width="180">
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | momentWu }}</span>
                </template>
              </el-table-column>
              <el-table-column label="认证状态" align="left" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                  {{ $setDictionary("HR_COMP_AUTH", scope.row.authState) }}
                </template>
              </el-table-column>
              <el-table-column label="最后登录时间" align="left" show-overflow-tooltip width="180">
                <template slot-scope="scope">
                  <span>{{ scope.row.lastLoginTime | momentWu }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审核状态" align="left" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("HR_ADMIN_AUDIT_STATE", scope.row.auditState) }}
                </template>
              </el-table-column>
              <el-table-column label="备注" align="left" prop="remark" show-overflow-tooltip width="200" />
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="handleCreate(scope.row)">{{ scope.row.auditState == '10' ? '编辑' : '审核' }}</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="handlePassword(scope.row)">重置密码</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="重置密码" :visible.sync="resetPasswordData.dialogVisible" width="30%" center>
      <div class="flexdcc">
        <p>是否将用户密码重置？</p>
        <p>重置后，变为初始密码Cc135246</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPasswordData.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doPassword" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/accountListHR",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        adminName: "", // 账号名称
        compName: "", // 机构名称
        mobile: "", // 手机号
        auditState: "", // 审核状态
        accountType: "", // 账号类别
      },
      // 审核状态 - 下拉数据
      auditStateList: [],
      // 账号类别 - 下拉数据
      accountTypeList: [],
      // 重置密码弹窗
      resetPasswordData: {
        dialogVisible: false, // 弹框状态
        adminId: "", // 班级id
      }
    };
  },
  computed: {},
  created() {
    this.getDictionary();
  },
  methods: {
    // 获取字典
    getDictionary() {
      // 审核状态
      let auditState = this.$setDictionary("HR_ADMIN_AUDIT_STATE", "list");
      for (const key in auditState) {
        this.auditStateList.push({
          label: auditState[key],
          value: key,
        });
      }
      // 账号类别
      let accountType = this.$setDictionary("ADMIN_QUERY_ROLE_KEY", "list");
      for (const key in accountType) {
        this.accountTypeList.push({
          value: key,
          label: accountType[key],
        });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchForm
      };
      this.doFetch({
        url: "/hr/admin/pageList",
        params,
        pageNum,
      });
    },
    // 审核
    handleCreate(row) {
      this.$router.push({
        path: "/web/operate/accountEditHR",
        query: {
          adminId: row.adminId, // 账号id
          auditState: row.auditState, // 审核状态:10已通过;20已驳回;00待审核
          authorizationScope: row.authorizationScope, // 账号权限
        },
      });
    },
    // 状态-启用&禁用
    handleStu(state, adminId) {
      this.$post("/sys/admin/modifyStat", {
        adminId,
        state,
      }).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getData(-1);
        }
      });
    },
    // 重置密码
    handlePassword(row) {
      this.resetPasswordData.dialogVisible = true;
      this.resetPasswordData.adminId = row.id;
    },
    // 重置密码 - 确定
    doPassword() {
      this.$post("/hr/admin/resetPassword", { userId: this.resetPasswordData.adminId }).then(
        (res) => {
          if (res.status == "0") {
            this.$message({
              type: "success",
              message: "密码重置成功",
            });
            this.DialogVisible = false;
            this.getData(-1);
          }
        }
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (from.path == '/web/operate/accountEditHR') {
        vm.getData(vm.pageNum)
      }
    })
  }
};
</script>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-switch__core {
    width: 40px !important;
  }

  .el-switch__core:after {
    background-color: #fff;
  }

  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

/deep/.el-input-group__prepend {
  width: 6rem;
}
</style>